.ngx-datatable.material {
	border: 1px solid #ddd;
	box-shadow: none;
	border-radius: 11px;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
	background-color: #535d6d;
	color: #fff;
	font-size: 11px;
	font-family: avenir;
}
.ngx-datatable.material .datatable-body-cell {
	font-size: 11px;
}

