.btn-w{
    width: 140px !important;
    min-width: unset!important;
    color: white !important;
}

.btn-outline{
    border-color: var(--blue-p)!important;
    border: 2px;
    color: var(--blue-p)!important;
}

.btn-nooutline{
    border-color: transparent!important;
    border: none;
    color: var(--blue-p)!important;
}

.btn-gray{
  color: var(--gray-474A4A) !important;
  background-color: var(--gray-ec) !important;
}

.bg-transp{
    background-color: transparent !important;
    box-shadow: none !important;
}

/* .mat-button-disabled {
  background-color: var(--blue-el) !important;
} */

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: var(--blue-p);
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: var(--blue-p);
}

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--blue-p);
}

.mat-calendar-body-selected {
  background-color: var(--blue-p);
}

.btn-upload-p {
  background-color: var(--blue-p);
  color: #fff;
  width: 140px !important;
  min-width: unset!important;
  padding: 10px !important;
}

.btn-upload-p:hover,
.btn-upload-p:active {
  background-color: var(--blue-l) !important;
  border-color: var(--blue-p) !important;
  box-shadow: 0 0 6px var(--blue-exl) !important;
}

.button-outlined-warn:not(.mat-button-disabled) {
	background-color: var(--white);
	color: var(--orange-b);
	border: 2px solid var(--orange-b);
}

.button-outlined-warn.mat-button-disabled,
.button-outlined.mat-button-disabled {
	background-color: var(--white) !important;
	color: #E3E9EC !important;
	border: 2px solid #E3E9EC !important;
}

.outlined-new {
	border: 1px solid var(--blue-p) !important;
	border-radius: 9px !important;
	min-width: 140px !important;
}

.outlined-new.mat-button-disabled {
	border: 1px solid var(--gray-ec) !important;
}

.button-export, .button-export-new {
	font-size: 16px;
	font-weight: 600;
	border-radius: 30px !important;
	width: 180px;
	margin: 0 0.5rem !important;
	height: fit-content;
}

.button-export-new {
  background-color: var(--color) !important;
}

.button-outlined:not(.mat-button-disabled),
.button-outlined-new:not(.mat-button-disabled) {
	background-color: var(--white) !important;
	color: var(--blue-p) !important;
	border-color: var(--blue-p) !important;
	border-width: 2px !important;
}

.button-outlined.mat-button-disabled, .button-outlined-new.mat-button-disabled {
	background-color: var(--white) !important;
	color: #e3e9ec !important;
	border-color: #e3e9ec !important;
	border-width: 2px !important;
}

/* .mat-button-disabled {
	background-color: var(--gray-ec) !important;
} */

.button-outlined-new:not(.mat-button-disabled) {
	color: var(--color) !important;
	border-color: var(--color) !important;
}
