.custom-table {
	width: 100%;
	border-collapse: collapse;
	border-radius: 1em;
	overflow: hidden;
}

.custom-table th,
td {
	background: white;
	border-bottom: 2px solid var(--gray-da);
}

th.mat-header-cell {
	background-color: var(--gray-ec);
}

.custom-table th {
	background-color: var(--gray-ec);
}

.custom-table table th:first-child,
table th:last-child {
	border-collapse: collapse;
}

.custom-table tbody tr {
	height: 48px;
	border-bottom: 2px solid var(--gray-da);
	border-left: 2px solid var(--gray-da);
	border-right: 2px solid var(--gray-da);
}

.mat-table {
	background-color: transparent !important;
}

.table-th-white th {
	background-color: #ffffff !important;
}

.table-spiner {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

/* Grid table */
.icon-search {
	padding: 15px !important;
	width: 30px !important;
	height: 41px !important;
	min-width: 30px !important;
	position: absolute !important;
}

.input-search {
	height: 40px !important;
	border-radius: 5px;
	padding-left: 28px;
}

.div-container-center {
	display: flex;
	justify-content: center;
}

.table-container {
	height: 365px;
	width: 97%;
	border-radius: 1em;
	padding: 0px;
}

.mat-paginator {
	display: flex;
	justify-content: center;
}

:root .mat-paginator {
	display: flex;
	justify-content: center;
}

:root .mat-header-cell {
	font-weight: 900;
	position: -webkit-sticky;
	position: sticky;
	top: -1px;
}

td.mat-cell:first-of-type {
	padding-left: 15px !important;
}

td.mat-cell:last-of-type {
	padding-right: 24px !important;
}

td.mat-cell {
	padding: 0 0.25rem !important;
}

.mat-sort-header-content {
	text-align: left !important;
}

/* END Grid table */

.container-design {
	/* max-height: 400px;
	height: 400px; */
	width: 100%;
	overflow-y: auto;
	flex-basis: content;
}

.design-table {
	height: auto;
	/* max-height: 400px; */
	width: 100%;
	border-spacing: 0 8px !important;
	border-collapse: separate;
}

.design-table td.mat-cell:not(.no-data) {
	padding: 16px 0 16px 0;
	border-bottom: 2px solid var(--table);
	border-top: 2px solid var(--table);
}

.design-table .error td.mat-cell:not(.no-data) {
	border-color: #ff0000 !important;
	background-color: rgba(247, 225, 225, 0.2) !important;
}

.design-table.gray td.mat-cell:not(.no-data) {
	border-bottom: 2px solid var(--gray-d7);
	border-top: 2px solid var(--gray-d7);
}

.design-table td.mat-cell:first-child:not(.no-data) {
  border-left: 2px solid var(--table);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.design-table.gray td.mat-cell:first-child:not(.no-data) {
	border-left: 2px solid var(--gray-d7);
}

.design-table td.mat-cell:last-child:not(.no-data) {
  border-right: 2px solid var(--table);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.design-table.gray td.mat-cell:last-child:not(.no-data) {
	border-right: 2px solid var(--gray-d7);
}

.design-table th.mat-header-cell {
	background-color: var(--white);
	color: #000000;
	font-weight: 500;
	font-size: 14px;
	border-bottom-width: 0;
}
/*
.design-table td {
	border-bottom: 0 !important;
}

.design-table tr.cdk-row {
	border: 2px solid var(--blue-p);
	border-radius: 15px; */
/* font-size: 15px;
}
*/
.design-table td.mat-cell {
	font-size: 15px;
	color: #000000;
	font-weight: 400;
}

.design-table td.mat-cell:first-of-type {
	padding-left: 24px !important;
}

.design-table .no-data {
	color: #d7dadd !important;
	font-weight: 700 !important;
	font-size: 1.5rem !important;
	border-bottom-width: 0px !important;
}
