.scroller {
	overflow-y: scroll;
	scrollbar-color: var(--scroll-color) transparent !important;
	scrollbar-width: thin !important;
	border-radius: 13px !important;
}

* {
	scrollbar-color: var(--scroll-color) transparent !important;
}

.ocre-scroll * {
	scrollbar-color: var(--nav-title) transparent !important;
}

.ocre-scroll *::-webkit-scrollbar {
	scrollbar-color: var(--nav-title) transparent !important;
}

/*CHROME*/
.scroller::-webkit-scrollbar {
	width: 10px !important;
	height: 10px !important;
	border-radius: 10px !important;
}

.scroller::-webkit-scrollbar-track {
	box-shadow: inset 0 0 8px grey;
	border-radius: 10px !important;
}

.scroller::-webkit-scrollbar-thumb {
	background: var(--scroll-color) !important;
	border-radius: 10px !important;
}

.scroller::-webkit-scrollbar-thumb:hover {
	background: var(--scroll-color);
}
