.mat-datepicker-content {
	border-radius: 10px !important;
}

.mat-calendar-header {
	background-color: var(--primary-00);
	color: var(--neutral-00);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.trader-theme .mat-calendar-arrow {
	fill: var(--neutral-00);
}

.project-theme .mat-calendar-arrow {
	fill: #000000;
}

.mat-calendar-previous-button::after {
	border: 0 solid var(--white);
	border-left-width: 2px;
	border-top-width: 2px;
}

.mat-calendar-controls {
	margin-top: 5px !important;
}

.mat-calendar-period-button {
	font-size: 16px;
}

.project-theme .mat-calendar-period-button {
	color: #000000;
}

.mat-calendar-next-button::after {
	border: 0 solid var(--white);
	border-right-width: 2px;
	border-top-width: 2px;
}

.mat-calendar-table-header {
	color: var(--primary-00);
}

.project-theme .mat-calendar-table-header {
	color: #000000;
}

.mat-calendar-table-header-divider:after {
	background-color: var(--primary-00);
}

@media (hover: hover) {
	.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
		> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
			.mat-calendar-body-comparison-identical
		) {
		background-color: var(--primary-00);
	}
}

.mat-calendar-body-in-range:before {
	background-color: var(--primary-light-60);
}

.mat-calendar-body-selected {
	background-color: var(--secondary-00) !important;
	color: #000000;
}

.project-theme .mat-calendar-body-selected {
	background-color: var(--primary-00) !important;
	color: #000000;
}

.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
		.mat-calendar-body-comparison-identical
	),
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
		.mat-calendar-body-comparison-identical
	) {
	background-color: var(--primary-light-60);
}
