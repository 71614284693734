.flexModalTitle{
    display:flex;
    justify-content: space-between;
}

.footerModal{
    background-color: #ececec;
    padding:4px;
}

.modal-excel .modal-dialog {
  width: 430px;
}