/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@import "~bootstrap/dist/css/bootstrap.css";
@import "./styles/colors.css";
@import "./styles/button.css";
@import "./styles/scroller.css";
@import "./styles/border.css";
@import "./styles/background.css";
@import "./styles/table.css";
@import "./styles/input.css";
@import "./styles/modal.css";
@import "./styles/font.css";
@import "./styles/tooltip.css";
@import "./styles/calendar.css";
@import "./styles/ngx-datatable.scss";

/*  CALENDAR  */
@import "~ng-pick-datetime/assets/style/picker.min.css";

* {
	font-family: "Poppins";
}

body {
	margin: 0;
	padding: 0;
	background-color: #ffffff;
	height: 100vh;
}

html,
body {
	font-family: "Poppins";
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-content {
	background-color: var(--blue-p) !important;
	color: #ffffff !important;
}

.div-add {
	display: inline-block;
}

.btn-confirm {
	color: #ffffff;
	background-color: var(--blue-p);
}

.btn-confirm:hover {
	color: #ffffff;
}

.error {
	color: #ff6c00;
}

.text-orange {
	color: var(--blue-p) !important;
}

.btn {
	min-width: 20% !important;
}

.background-orange {
	background-color: var(--blue-p);
}

/******** (START) CUSTOM SCROLL **********/
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--scroll-color);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--scroll-color);
}
/******** (END) CUSTOM SCROLL **********/

.mat-paginator {
	background: transparent !important;
}

.mat-cell {
	padding-left: 2px;
	padding-right: 5px;
}

.title-trade {
	font-size: 42px;
	font-family: Avenir, Arial, Helvetica, sans-serif;
	text-align: center;
	color: #555d6f;
	margin-bottom: 30px;
	margin-top: 20px;
}

.subtitle {
	font-size: 22px;
	font-family: Avenir, Arial, Helvetica, sans-serif;
	font-weight: lighter !important;
	text-align: center;
	color: gray;
}

.title-desc {
	font-size: 30px;
	font-family: Avenir, Arial, Helvetica, sans-serif;
	font-weight: lighter !important;
	text-align: center;
	color: gray;
}

.margin-end {
	margin-bottom: 20px;
	margin-block: 20px;
}

.col-center {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.imgNoData {
	text-align: right !important;
	margin-right: 30px;
}

.titleNoData {
	font-size: 38px;
	font-family: Avenir;
	font-style: unset;
	color: #555d6f;
}

.msgNoData {
	font-size: 22px;
	font-family: Avenir;
	font-weight: lighter !important;
	color: gray;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	position: absolute;
	z-index: 9999999 !important;
}

.ngx-datatable.material {
	border: 1px solid #dddddd;
	box-shadow: none;
	border-radius: 11px;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-700 {
	font-weight: 700 !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
	background: var(--blue-l);
}

.owl-dt-container-info .owl-dt-container-info-active {
	color: var(--blue-p);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	color: rgba(255, 255, 255, 0.85);
	background-color: var(--blue-p);
}

.owl-dt-container-buttons {
	color: var(--blue-p);
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
} */

.position-stick {
	position: -webkit-sticky;
	position: sticky;
	top: -1px;
	z-index: 10;
	background-color: var(--white);
}

.content.super .div-container-form, .content.super .editor-container {
	height: 90% !important;
}

.div-container-form {
	height: 100%;
	overflow-x: hidden;
	width: 99.8%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.mat-drawer-content {
	overflow: hidden !important;
}

.cursor {
	cursor: pointer;
}
/*
.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
*/

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--blue-p);
}

.check-verde .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--green-p) !important;
}
/****   COLORS    ****/

.color-orange {
	color: var(--orange-p);
}

.color-red {
	color: var(--error) !important;
}

.color-green {
	color: green;
}

.color-gray {
	color: var(--gray-da);
}

.color-blue {
	color: var(--blue-p);
}

.ng-star-inserted .mat-calendar-table td {
	border-bottom: 0px !important;
}

.owl-dt-calendar-view table td {
	border-bottom: 0px !important;
}

.mat-dialog-container {
	overflow-y: hidden !important;
	overflow-x: hidden !important;
	padding: 0px !important;
}

.modal-header {
	justify-content: left;
}

.popover {
	max-width: 180px !important;
	font-size: 0.75rem !important;
}

.popover-header {
	font-size: 12px !important;
	margin: 0 !important;
}

.popover-body {
	padding: 0.8rem 1rem !important;
}

/* BREADCRUMB */

.breadcrumb {
	margin: 0 !important;
	align-items: center;
	--bs-breadcrumb-item-padding-x: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item:before {
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCI+PHBhdGggZD0ibTggMTUtMS4wNjItMS4wNjJMMTAuODc1IDEwIDYuOTM4IDYuMDYyIDggNWw1IDVaIi8+PC9zdmc+");
}
.breadcrumb-item {
	font-weight: 400;
}

.breadcrumb-item:last-child {
	font-weight: 600 !important;
	color: var(--nav-title);
}

.breadcrumb-item:first-child {
	padding-top: 2px;
}

.breadcrumb-item + .breadcrumb-item {
	padding-top: 5px;
}
