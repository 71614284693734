.tooltip-app {
  border-width: 2px;
  border-style: solid;
  border-radius: 7px;
  background-color: var(--tooltip-bg);
  padding: 5px 10px;
  width: 250px;
  min-height: 20px;
  position: absolute;
  z-index: 10;
  font-size: 10px;
  border-color: var(--tooltip-border);
}

.tooltip-app-header {
  background-color: var(--primary-00);
  color: var(--text);
  margin: -5px -10px 0 -10px;
  padding: 10px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.tooltip-app.top-left {
  clip-path: polygon(13.5% 0%, 89.7% 0.6%, 100% 0%, 100% 100%, 83.3% 100%, 6.1% 100%, 0% 88.0%, 0% 0%);
  /* background: radial-gradient(1px 1px at 0% 83px, rgba(0, 0, 0, 0) 8px, #FFF7F0 8px); */
}

.tooltip-app.bottom-left {
  clip-path: polygon(6.7% 0%, 83.3% 0%, 100% 1.1%, 100% 100%, 83.3% 100%, 16.7% 100%, 0% 100%, 0% 5.7%);
  background: radial-gradient(50px 50px at 0% 0px, rgba(0, 0, 0, 0) 10px, #FFF7F0 10px);
}
.tooltip-app.bottom-right {
  clip-path: polygon(30% 0%, 95% 0%, 100% 7.3%, 100% 70%, 100% 100%, 30% 100%, 0% 100%, 0% 0%);
  background: radial-gradient(50px 50px at 0% 0px, rgba(0, 0, 0, 0) 10px, #FFF7F0 10px);
}

.tooltip-app::before {
  content: '';
  background-color: transparent;
  width: 5px;
  height: 5px;
}
/* element.style {
  background: linear-gradient(141deg, transparent 9px, #FFF7F0 10px); 
  left: 310.906px;
  top: 578.281px;
  background: radial-gradient(50px 50px at 0% 0px, rgba(0, 0, 0, 0) 10px, #FFF7F0 10px);
  clip-path: polygon(7.7% 0%, 83.3% 0%, 100% 1.1%, 100% 100%, 83.3% 100%, 16.7% 100%, 0% 100%, 0% 6.7%);
} */