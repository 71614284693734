.border-r {
    -webkit-border-radius: 9px !important;
    -moz-border-radius   : 9px !important;
    border-radius        : 9px !important;
}

.border-s {
    -moz-box-shadow   : 0px 5px 7px 0px #A0A0A0 !important;
    -webkit-box-shadow: 0px 5px 7px 0px #A0A0A0 !important;
    box-shadow        : 0px 5px 7px 0px #A0A0A0 !important;
    border            : none;
}

.div-content{
    width: 99.8%;
}